.container {
  display: flex;
}
.leftSide {
  background-color: #18a0fb;
  color: #ffffff;
  flex-basis: 450px;
  flex-shrink: 0;
  height: 100vh;
  padding: 2rem 4rem;
  font-size: 14px;
  line-height: 16px;
  position: relative;

  &_headTitle {
    margin: 2rem 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }
}

.rightSide {
  width: 100%;
  padding: 2rem;
  background-color: #ffffff;
}

.topButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  .register {
    font-weight: 500;
    background: #f1f2f4;
    box-shadow: 0px 5px 5px rgba(238, 239, 239, 0.15);
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1rem;
    padding: 14px 18px;

    &:hover {
      background: #e1e4e8;
    }
  }
}

.content {
  width: 80%;
  margin: 2rem auto;
}

.content_lg {
  width: 95%;
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  margin-bottom: 2rem;
  color: #29394d;
}

.list_title {
  margin: 1rem 0;
}
.list {
  li {
    list-style-type: disc;
    margin-left: 15px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem;

  button {
    margin-left: 10px;
  }
}

.successMsg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  color: #06d9b1;
  text-align: center;

  i {
    font-size: 80px;
    margin-bottom: 2rem;

    &::before {
      color: #06d9b1;
    }
  }

  span {
    font-size: 32px;
    line-height: 36px;
  }

  &_send {
    margin-top: 1rem;
    font-size: 22px !important;
    line-height: 26px !important;
    cursor: pointer;
  }
}

.link {
  color: #18a0fb;
  cursor: pointer;
}

.emailInputBox {
  position: relative;

  i {
    position: absolute;
    right: 10px;
    bottom: 27px;
    color: #06d9b1;

    &::before {
      color: #06d9b1;
    }
  }
}
.emailInput {
  background: #ffffff;
  border: 1px solid #18a0fb;
}

.pwdInput {
  &.error {
    border: 1px solid #d50000;
  }
}
span.error {
  color: #d50000;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}

.helpText {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6d7f92;
}

.contentLg {
  margin: 0 2rem;

  &_grid {
    display: flex;
  }

  &_left {
    width: 50%;
    padding-right: 2rem;
    border-right: 1px solid #d8dde2;
  }

  &_right {
    width: 50%;
    padding-left: 2rem;
  }
}

.acceptActions {
  max-width: 450px;
  margin: 2rem auto;
}

.acceptError {
  font-size: 12px;
  line-height: 14px;
  color: #fe3745;
  text-align: center;
  margin-top: 10px;
}

.checkbox {
  margin: 0 40px 0 auto;
  font-size: 14px;
  line-height: 16px;
  color: #6d7f92;
  display: flex;
  align-items: flex-start;

  input {
    margin-right: 10px;
  }

  // span {
  //   color: #18a0fb;
  //   cursor: pointer;
  // }
}

.passwordBox {
  position: relative;
}

.progress {
  position: absolute;
  bottom: 5px;
  left: 0;
  margin: 0;
  width: 45%;
  height: 3px;
  border-radius: 10px;
  background: #d8dde2;

  &.error {
    color: #fe3745;
  }
}

.agreationBox {
  display: flex;
  background: #f8f9fa;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
}

.agreationSelect {
  width: 65%;
}

.agreation {
  width: 35%;
  border-left: 1px solid #b2bcc6;
  box-sizing: border-box;
  padding-left: 10px;
  height: 34px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  input {
    flex: 2;
    border: none;
    background: transparent;
    font-size: 14px;
  }
}

.btnChangePwd {
  position: relative;
  > span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #18a0fb;
    cursor: pointer;
    z-index: 9;
  }
}

@media screen and (max-width: 39.9375em) {
  .content {
    width: 100%;
  }

  .leftSide {
    padding: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column-reverse;
  }
}

.labelTitle {
  display: block;
  margin: 2rem 0 1rem;
}

.codeInput {
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none !important;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input {
    margin-left: 5px;
  }
}

.codeBox  {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.codeError {
  color: #d50000;
  font-size: 12px;
  display: block;
  line-height: 16px;
  margin-top: 4px;
}

.phoneGroup {
  min-height: 70px;
  input {
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
.errorSpan {
  color: #d50000 !important;
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 16px;
  margin-top: 4px;
}

.card {
  background: #ffffff;
  border: 1px solid #b2bcc6;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  margin-bottom: 10px;

  &.disabled {
    cursor: not-allowed;
  }
  &.selected {
    background: #f3faff;
    border: 1px solid #18a0fb;
    .check {
      background: #18a0fb;
      border: none;
      font-size: 10px;
      i:before {
        color: #fff;
      }
    }
  }
  label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
    flex: 1;
    cursor: pointer;
    span {
      color: #18a0fb;
      font-weight: 500;
    }
  }

  .check {
    width: 18px;
    height: 18px;
    background: #f8f9fa;
    border: 2px solid #b2bcc6;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
