:global(.rmodal) {
  position: relative;
  height: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;
  padding: 1rem 1rem 5rem;
  overflow: scroll;
}
.close {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 999999;
  font-size: 2rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.acceptActions {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  label {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
  }
}

.vatbox {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}

.title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 1rem 0 2rem;
}

.modalActions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 10;
}

.discountLabel {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  display: block;
}
.discountRow {
  border-bottom: 1px solid #b2bcc6;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
}

.switchRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-height: 34px;

  &_left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.statRow {
  border: 1px solid #e1e4e8;
  border-radius: 20px;
  width: 320px;
  padding: 5px 10px;
  margin: 0 auto 20px;
  font-size: 14px;
  text-align: center;
}
